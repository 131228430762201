body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.button{
  background-color: white;
  margin-top: 10px;
  padding: 10px;
  color:black;
  box-shadow: 0 0 12px 0 #eee;
  border-radius: 10px;
}
.containerTran {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.language-dropdown {
  width: 318px;
  height: 48px;
  border: 0;
  color: black;
  box-shadow: 0 0 12px 0 #eee;
  padding-left: 15px;
  margin-left: 0;
  padding-right: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;
  background: url('data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    98% 50% no-repeat #fff;
}

input,
textarea {
  width: 100%;
  height: 40px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 12px 0px #eee;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.2rem;
  font-family: sans-serif;
  width: 300px;
  line-height: 2rem;
}

label {
  margin-bottom: 12px;
}

.spacer {
  margin-bottom: 32px;
}

._ReactTransliterate_1b0d4b > li{
  background-color: black;
 font-weight: bold;
}
._ReactTransliterate_1b0d4b > ._Active_1b0d4b{

  background-color: rgb(82, 175, 225);
}

