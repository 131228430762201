.coin-popup {
    position: fixed;
    top: 15%;
    left: 0;
    width: 100%;
    height:100%;
    background: rgba(0, 0, 0, 0.5);
     display: flex;
    /* align-items: center; */
    justify-content: center;
    overflow-y: auto;
    transition: 10s;
    z-index: 99;
  }
  .coin-popup::-webkit-scrollbar {
    display: none;
}
  
  .coin-popup-inner {
    background: #2C3639;
    padding: 50px;
    /* width:90%; */
    width: fit-content;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:fit-content;
    flex-direction: column;
    
  }
  .coin-popup .topconatainer{
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .coin-popup .pop-div1{
    width:250px;
    height: fit-content;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 50px;
    background-color: #BFDB38;
  }
  .coin-popup .pop-div1 p{
    font-size: 13px;
  }
  .coin-popup .pop-div1 button{
    width: 110px;
    height: 35px;
    border-radius: 20px;
    border: none;
    color: white;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: #2C3639;
    cursor: pointer;

  }
  .coin-popup .pop-div1 button:hover{
    background-color: #181c1d;
  }
  .coin-popup .icon{
    position: absolute;
    z-index: 10;
    margin-left: 450px;
    margin-top: -73px;
  }


 

