.wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
  padding: 2rem;
  background-color: #eee;
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 45px;
  background-color: #fff;
  height: 100%;
  border-radius: 0.25rem;
  box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
  
  &__heading {
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
    text-align: center;
    
    & > h2 {
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin: 0;
    }
  }
  
  &__content {
    overflow-y: scroll;
    scrollbar-color: white!important;
     scrollbar-width: none!important;
  }
  
  &__nav {
    border-top: 1px solid #ccc;
    text-align: right;
    padding: 2rem 0 1rem;
    
    & > .button {
      background-color: #444499;
      box-shadow: 0rem 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
      padding: 0.8rem 2rem;
      border-radius: 0.5rem;
      color: #fff;
      text-decoration: none;
      font-size: 0.9rem;
      transition: transform 0.25s, box-shadow 0.25s;
      
      &:hover {
        box-shadow: 0rem 0rem 1rem -0.125rem rgba(0, 0, 0, 0.25);
        transform: translateY(-0.5rem);
      }
    }
    
    & > small {
      color: #777;
      margin-right: 1rem;
    }

  }
}

.container__content p {
  font-size: 15px;
   font-weight: 500;
   line-height: 25px;
}

.container__content span {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

.container__content h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}