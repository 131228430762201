.custom-side-panel {
  background-color: black !important;
}

.SidePanel .YourIconClass {
  fill: red; 
}

.bp5-dark .polotno-side-tabs-container .polotno-side-panel-tab:hover,
.bp5-dark .polotno-side-tabs-container .polotno-side-panel-tab.active {
  background-color: #c8c52d !important;
  color: black !important;
}

.bp5-dark .bp5-button:not([class*="bp5-intent-"]):hover {
  background-color: #c8c52d;
}

.bp5-dark .polotno-side-panel-icon {
  color: #ff0000;
}

